// src/Helpdesk.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Helpdesk.css';
import { AuthContext } from './AuthContext';

const Helpdesk = () => {
  const { auth } = useContext(AuthContext);
  const [requests, setRequests] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (!auth || !auth.token) {
      window.location.href = '/login';
      return;
    }
    fetchRequests();
  }, [auth, selectedDate]);

  const fetchRequests = async () => {
    try {
      const formattedDate = selectedDate.toISOString().split('T')[0];
      console.log(`Fetching requests for date: ${formattedDate}`);
      const res = await axios.get('https://help.pskovmuseum.ru/api/requests', {
        headers: { Authorization: `Bearer ${auth.token}` },
        params: { date: formattedDate }
      });
      console.log('Received requests:', res.data);
      setRequests(res.data);
    } catch (err) {
      console.error('Error fetching requests:', err);
    }
  };

  const addRequest = async () => {
    if (!description) {
      alert('Описание запроса не может быть пустым');
      return;
    }
    try {
      await axios.post(
        'https://help.pskovmuseum.ru/api/requests',
        { description },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setDescription('');
      fetchRequests();
    } catch (err) {
      console.error('Error adding request:', err);
    }
  };

  const completeRequest = async (id) => {
    try {
      await axios.post(
        `https://help.pskovmuseum.ru/api/requests/${id}/complete`,
        {},
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      fetchRequests();
    } catch (err) {
      console.error('Error completing request:', err);
    }
  };

  return (
    <div className="container-form">
      <h1>Запросы службы поддержки</h1>
      <div className="form-group">
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Введите описание запроса"
          required
        />
        <button className="add" onClick={addRequest}>
          <div className="svg-wrapper-1">
            <div className="svg-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  fill="currentColor"
                  d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                ></path>
              </svg>
            </div>
          </div>
          <span>Добавить запрос</span>
        </button>
      </div>
      <div className="form-group">
        <DatePicker
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div id="checklist">
        {requests.map(request => (
          <div key={request.id} className="checklist-item">
            <input
              type="checkbox"
              checked={request.status === 'Completed'}
              onChange={() => completeRequest(request.id)}
              id={`request-${request.id}`}
            />
            <label htmlFor={`request-${request.id}`}>
              {request.description}
            </label>
            {request.user_name && (
              <span className="user-info">
                {request.user_name} ({request.user_department})
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Helpdesk;
